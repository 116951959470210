var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    {
      attrs: { icon: _vm.$route.meta.icon, title: _vm.$route.meta.title },
      scopedSlots: _vm._u([
        {
          key: "tools",
          fn: function() {
            return [
              !_vm.isAnima()
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          color: "purple darken-1",
                                          icon: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.download(44)
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-microsoft-excel")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3447134281
                      )
                    },
                    [_c("span", [_vm._v(" Relatório Resumido ")])]
                  )
                : _vm._e(),
              _c("s-icon-report-download", {
                attrs: {
                  "tool-tip": "Relatório Completo",
                  report: "43",
                  "show-message": ""
                }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("emc-loader-progress", { attrs: { show: _vm.loadingProgress } }),
      _c("emc-avatar-show", {
        attrs: {
          model: _vm.selectedTeacher,
          show: _vm.showImage,
          source: _vm.selectedTeacher ? _vm.selectedTeacher.url_avatar : null
        },
        on: {
          "update:show": function($event) {
            _vm.showImage = $event
          }
        }
      }),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "text-h5 grey lighten-2" },
                [
                  _vm._v(" Extrato do Professor "),
                  _c("v-spacer"),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "mr-1",
                                      attrs: { icon: "" }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: { color: "red lighten-1" },
                                      on: {
                                        click: function($event) {
                                          return _vm.$refs.extract.createPDF()
                                        }
                                      }
                                    },
                                    [_vm._v("mdi-file-pdf-box")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v("Imprimir Extrato")])]
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "mr-1",
                                      attrs: { icon: "", small: "" },
                                      nativeOn: {
                                        click: function($event) {
                                          _vm.dialog = false
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "red lighten-2" } },
                                    [_vm._v("mdi-close")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v("Fechar")])]
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c("SysTeacherExtract", {
                    ref: "extract",
                    attrs: { teacher: _vm.selectedItem }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "12" } },
            [
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.itemsFiltered,
                  "fixed-header": "",
                  height: "450",
                  search: _vm.search,
                  "custom-filter": _vm.customFilter
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.teacher",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("emc-avatar-show-image", {
                            attrs: { model: item.teacher },
                            on: {
                              click: function($event) {
                                return _vm.showAvatar(item.teacher)
                              }
                            }
                          }),
                          _vm._v(" " + _vm._s(item.teacher.name) + " ")
                        ]
                      }
                    },
                    _vm._l(
                      _vm.headers.filter(function(h) {
                        return h.period
                      }),
                      function(h, i) {
                        return {
                          key: "item." + h.value,
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("span", { key: i }, [
                                item[h.value] != null
                                  ? _c(
                                      "span",
                                      { staticStyle: { cursor: "help" } },
                                      [
                                        item[h.value + ":act"] != null ||
                                        item[h.value + ":ava"] != null
                                          ? _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        var attrs = ref.attrs
                                                        return [
                                                          _c(
                                                            "span",
                                                            _vm._g(
                                                              _vm._b(
                                                                {},
                                                                "span",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              item[
                                                                h.value + ":act"
                                                              ]
                                                                ? _c(
                                                                    "v-badge",
                                                                    {
                                                                      attrs: {
                                                                        color:
                                                                          "blue lighten-4",
                                                                        dot: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            item[
                                                                              h
                                                                                .value
                                                                            ]
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item[
                                                                          h
                                                                            .value
                                                                        ]
                                                                      )
                                                                    )
                                                                  ])
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _c("span", [
                                                  item[h.value + ":act"]
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " Sala de Aula: " +
                                                            _vm._s(
                                                              item[
                                                                h.value + ":wld"
                                                              ]
                                                            ) +
                                                            " "
                                                        ),
                                                        _c("br"),
                                                        _vm._v(
                                                          " Atividades: " +
                                                            _vm._s(
                                                              item[
                                                                h.value + ":act"
                                                              ]
                                                            ) +
                                                            " "
                                                        ),
                                                        _c("br")
                                                      ])
                                                    : _vm._e(),
                                                  _vm._v(
                                                    " Disponibilidade: " +
                                                      _vm._s(
                                                        item[h.value + ":ava"]
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              ]
                                            )
                                          : _c("span", [
                                              _vm._v(_vm._s(item[h.value]))
                                            ])
                                      ],
                                      1
                                    )
                                  : _c("span", [_vm._v("--")])
                              ])
                            ]
                          }
                        }
                      }
                    ),
                    {
                      key: "item.variation",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-chip",
                            {
                              staticStyle: {
                                width: "60px",
                                "justify-content": "center"
                              },
                              attrs: {
                                dark: "",
                                outlined: "",
                                color: item.variation > 0 ? "error" : "grey"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.variation
                                      ? parseFloat(item.variation).toFixed(2)
                                      : "---"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]
                      }
                    },
                    {
                      key: "item.balance",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-chip",
                            {
                              staticStyle: {
                                width: "60px",
                                "justify-content": "center"
                              },
                              attrs: {
                                dark: "",
                                outlined: "",
                                color: _vm.getIconStatus(item).color
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(parseFloat(item.balance).toFixed(2)) +
                                  " "
                              )
                            ]
                          )
                        ]
                      }
                    },
                    {
                      key: "item.status",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-chip",
                            {
                              staticClass: "ma-2",
                              staticStyle: { width: "130px" },
                              attrs: {
                                color: _vm.getIconStatus(item).color,
                                outlined: ""
                              }
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.getIconStatus(item).icon) +
                                    " "
                                )
                              ]),
                              _vm._v(
                                " " + _vm._s(_vm.getIconStatus(item).name) + " "
                              )
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "item.actions",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            {
                                              attrs: {
                                                small: "",
                                                color: "orange darken-4",
                                                right: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.openExtract(item)
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [_vm._v(" mdi-open-in-new ")]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [_c("span", [_vm._v("Abrir Extrato")])]
                          )
                        ]
                      }
                    },
                    {
                      key: "footer.prepend",
                      fn: function() {
                        return [
                          _c(
                            "v-chip-group",
                            {
                              attrs: { multiple: "" },
                              model: {
                                value: _vm.filters,
                                callback: function($$v) {
                                  _vm.filters = $$v
                                },
                                expression: "filters"
                              }
                            },
                            _vm._l(_vm.tags, function(tag, i) {
                              return _c(
                                "v-chip",
                                {
                                  key: i,
                                  staticClass: "ma-2",
                                  attrs: {
                                    color: _vm.filters.includes(i)
                                      ? tag.color
                                      : "grey",
                                    "close-icon": _vm.filters.includes(i)
                                      ? _vm.icons[0]
                                      : _vm.icons[1],
                                    close: "",
                                    outlined: "",
                                    height: "25",
                                    small: ""
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { small: "", left: "" } },
                                    [_vm._v(" " + _vm._s(tag.icon) + " ")]
                                  ),
                                  _c("small", [
                                    _vm._v(
                                      _vm._s(tag.name) +
                                        " (" +
                                        _vm._s(_vm.summary[tag.value]) +
                                        ")"
                                    )
                                  ])
                                ],
                                1
                              )
                            }),
                            1
                          ),
                          _vm.acesso.instituicao.calculate_variation
                            ? _c(
                                "small",
                                [
                                  _c("v-switch", {
                                    attrs: {
                                      inset: "",
                                      label:
                                        "Com resilição (" +
                                        _vm.summary.with_variation +
                                        ")"
                                    },
                                    model: {
                                      value: _vm.onlyVariation,
                                      callback: function($$v) {
                                        _vm.onlyVariation = $$v
                                      },
                                      expression: "onlyVariation"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("v-text-field", {
                            attrs: {
                              "prepend-inner-icon": "search",
                              "x-small": "",
                              placeholder: "Pesquisar",
                              rounded: "",
                              "single-line": "",
                              clearable: "",
                              autofocus: ""
                            },
                            model: {
                              value: _vm.search,
                              callback: function($$v) {
                                _vm.search = $$v
                              },
                              expression: "search"
                            }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        ],
        1
      ),
      _c("emc-alert-snackbar", {
        attrs: {
          show: !!_vm.message.length,
          message: _vm.message,
          color: _vm.error ? "error" : "success"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }